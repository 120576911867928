<!--
#########################################################################################
######    Vista SVC00801  :  Recuperación de Contraseña                            ######
#########################################################################################
-->

<template>
<div>
  
  <TNC10000></TNC10000>

  <div class="igs-color2 igs-center igs-bold">
    <br/>
    Recuperar Contraseña
  </div>

  <div class="jumbotron vertical-center igs-back-color9"> 

    <div class="container">
      <div class="row">
          <div class="col-12 igs-center">
            <img :src="f99ImageName('ih1-logo-cuadro-mando')" height="120"> <br/>
          </div>
          <div class="col-12" style="height:32px">&nbsp;</div>
          <div class="col-12">
            <img :src="f99ImageName('ih1-usuario-azul')" width="80"> <br/><br/>
          </div>
      </div>

      <div class="row">
          <div class="col-1">&nbsp;</div>
          <div class="col-2 igs-center">
            <img :src="f99ImageName('ih1-mail-azul')" width="48"> 
          </div>
          <div class="col-8 igs-left igs-l-pad-20 igs-v-center">
             <div class="form-group">
                <input type="email" v-model="correo" :isEnabled="!$store.state.strRecibiendoDatos" class="form-control" aria-describedby="hEMail" placeholder="Dirección de Correo">
                <small id="hEmail" class="form-text text-muted">Te enviaremos un mensaje con las instrucciones necesarias a fin de recuperar tu contraseña.</small>
             </div>
          </div>
          <div class="col-1">&nbsp;</div>
      </div>

      <div class="row">
          <div class="col-12 igs-center">
            <br/>
            <img :src="f99ImageName(lf99ImagenOK)" width="48" @click="lp70RecuperarPassword()"> 
          </div>
      </div>

      <div class="row" style="height:120px">
        &nbsp;
      </div>
      
      <div class="row">
        <div class="col-5 igs-center" @click="lp70Inicio()">Identificarse</div>
        <div class="col-2 igs-center">&nbsp;|&nbsp;</div> 
        <div class="col-5 igs-center" @click="lp70Login(true)">DEMO</div>
      </div>
    </div>

  </div>

  <BNC10000></BNC10000>

</div>
</template>

<script>
import igs_mixin from '@/igs-mixins/igs_mixin'
const  axios = require("axios")

import TNC10000  from '@/igs-navegacion/TNC10000'
import BNC10000  from '@/igs-navegacion/BNC10000'

export default {
  mixins: [ igs_mixin ],
  components : { TNC10000, BNC10000 },

  //=======================================================================
  //------------------------ Variables Locales  ---------------------------
  //=======================================================================
  data() {
    return {
      correo : ''
    }
  },

  //=======================================================================
  //------------------------ Variables Calculadas  ---------------------------
  //=======================================================================
  computed : {

      //-------------------------------------------------------------------
      //-- Nombre de la Imagen a Mostrar para presionar OK
      //-------------------------------------------------------------------
      lf99ImagenOK () {

          if (this.correo == "")  {
              return "ih1-ok-gris"
          } else {
              return "ih1-ok-azul"
          }

      }
  },


  //=======================================================================
  //------------------------ Métodos Locales  ---------------------------
  //=======================================================================
  methods: {

    //--------------------------------------------------------------------------------
    //-- Animación Inicial al Mostrar la Página de Login
    //--------------------------------------------------------------------------------
    // eslint-disable-next-line no-unused-vars
    onPageLoaded (args) {

      //-- Ocultar Indicadores de Actividad
      this.$store.commit('p10StrRecibiendoDatos', false);

    },

    //--------------------------------------------------------------------------------
    //-- Regresar al Menú Principal
    //--------------------------------------------------------------------------------
    lp70Inicio() {
        //-- Mostrar el Menu Principal
        this.$router.push('/');
    },

    //--------------------------------------------------------------------------------
    //-- Validación del Usuario y Contraseña (y Login en caso de éxito)
    //--------------------------------------------------------------------------------
    // eslint-disable-next-line no-unused-vars
    lp70Login(modoDemo) {

      console.info('Intento del Login del Mail ('+this.correo+')');

      //-- Determinar la URL a contactar
      var valorMetodo = "901";
      var URL = global.lnkServerAppURL + "&s=&t=999&m="+valorMetodo+"&e="+this.f75OfuscarTexto(this.correo);

      //-- Mostrar el/los indicadores de actividad oportunos
      this.$store.commit('p10StrRecibiendoDatos', true);
        
      //-- Solicitud de Datos al Servidor 
      axios.get(URL, {responseEncoding: 'utf-8'})
      .then(respuesta => {
        
            //-- Desactivar los Indicadores de Actividad
            this.$store.commit('p10StrRecibiendoDatos', false);

            //-- Extraer la respuesta recibida
            var datos = respuesta.data;

            //-- Determinar si ha habido EXITO ó ERROR
            // eslint-disable-next-line no-unused-vars
            var flg = global.lnkGetDataError;
            if (datos.flg) {  flg = datos.flg; }

            //-- Actualizar Resultados
            if ((datos.flg == global.lnkGetDataOk) && (datos.sesion)) {

              if (datos.usuario) {
                //----------------------------------------------------------------------------------------
                //-- Conservar las propiedades del NUEVO USUARIO
                //----------------------------------------------------------------------------------------
                this.$store.commit('p10StrUsuario', datos.usuario);
                this.$store.commit('p10StrDescripcionUsuario', datos.descripcionUsuario);
                this.$store.commit('p10StrMailUsuario', this.correo);

                //-- Conservar la Relación de Distribuidores/Clientes
                this.$store.commit('p10StrListaUDA', datos.listaUDA);

                //----------------------------------------------------------------------------------------
                //-- Establecer como Valor Actual el PRIMER Distribuidor/Cliente de la Lista Recibida 
                //----------------------------------------------------------------------------------------
                if (datos.listaUDA.length > 0) {

                  //-- Conservar las propiedades del NUEVO DISTRIBUIDOR
                  this.$store.commit('p10StrDistribuidor', datos.listaUDA[0].d1);
                  this.$store.commit('p10StrDescripcionDistribuidor', datos.listaUDA[0].d2);
                  this.$store.commit('p10StrDistribuidorListaImagenColor', datos.listaUDA[0].dlic);
                  this.$store.commit('p10StrNifDistribuidorOfuscado', this.f75OfuscarTexto(datos.listaUDA[0].d3));
                  this.$store.commit('p10StrFormatoRepresentacionMapa', datos.listaUDA[0].d5);
                  //this.p90AplicarCSS(datos.listaUDA[0].dcss);
                  //this.p90AplicarEstiloSB(datos.listaUDA[0].dlic[0]); 
                  
                } else {

                  //-- Conservar las propiedades del NUEVO DISTRIBUIDOR
                  this.$store.commit('p10StrDistribuidor', '??????');
                  this.$store.commit('p10StrDescripcionDistribuidor', '-- Distribuidor Desconocido --');
                  this.$store.commit('p10StrDistribuidorListaImagenColor', global.lnkDefaultLIC);
                  this.$store.commit('p10StrNifDistribuidorOfuscado', '');
                  this.$store.commit('p10StrFormatoRepresentacionMapa', '1');
                  //this.p90AplicarCSS(global.lnkDefaultCSS);
                  //this.p90AplicarEstiloSB(global.lnkDefaultLIC[0]); 
                  
                }
                
                //----------------------------------------------------------------------------------------
                //-- Conservar el Nro. de Sesión, y asumir "Sesión Viva"
                //----------------------------------------------------------------------------------------
                this.$store.commit('p10StrIDSesion', datos.sesion);
                this.$store.commit('p10StrUsuarioAutenticado', true);
                this.$store.commit('p10StrAbandonarSesion', false);

                //----------------------------------------------------------------------------------------
                //-- Acceder a la Página Principal de Resultados
                //----------------------------------------------------------------------------------------
                this.$navigateTo(this.$lnkListaPaginas.SVC00001, { clearHistory: true, frame: "mainContent"  });

              } else {
                this.p99AnuncioIA1('2','7','ERROR', '(G00) Código de Usuario Desconocido',-1);
              }


            } else {
              //console.dir(datos);
              if (datos.msg) { 
                this.p99AnuncioIA1('2','7','ERROR', this.f79DecodeHTML(datos.msg),-1);
              } else {
                this.p99AnuncioIA1('2','7','ERROR', '(E999901) Error Desconocido',-1);
              }

            }


        })
        .catch(error => {
          //-- Desactivar los Indicadores de Actividad
          this.$store.commit('p10StrRecibiendoDatos', false);

          //-- Mostrar el Mensaje de Error oportuno
          this.p99AnuncioIA1('2','7','ERROR', error.message,-1);
        });

    },    

    //--------------------------------------------------------------------------------
    //-- Solicitar Recuperación de Password
    //--------------------------------------------------------------------------------
    lp70RecuperarPassword() {

      console.info('Intento de Recuperación de Passsword para el Mail ('+this.correo+')');

      //-- Determinar la URL a contactar
      var URL = global.lnkServerAppURL + "&s=&t=999&m=801&e="+this.f75OfuscarTexto(this.correo);
      //console.log(URL);

      //-- Mostrar el/los indicadores de actividad oportunos
      this.$store.commit('p10StrRecibiendoDatos', true);
        
      //-- Solicitud de Datos al Servidor 
      axios.get(URL, {responseEncoding: 'utf-8'})
      .then(respuesta => {
        
            //-- Desactivar los Indicadores de Actividad
            this.$store.commit('p10StrRecibiendoDatos', false);

            //-- Extraer la respuesta recibida
            var datos = respuesta.data;

            //-- Determinar si ha habido EXITO ó ERROR
            // eslint-disable-next-line no-unused-vars
            var flg = global.lnkGetDataError;
            if (datos.flg) {  flg = datos.flg; }

            //-- Evaluar si ha habido EXITO
            if (datos.flg == global.lnkGetDataOk) {

              //-------------------------------------------------------------------
              //-- Informar al usuario que la tarea ha sido concluida con EXITO
              //-------------------------------------------------------------------
              this.p99AnuncioIA1('1','5',global.lnkAppName, "Ya ha sido enviado un correo con las instrucciones necesarias",-1);

              //----------------------------------------------------------------------------------------
              //-- Mostrar la Pantalla de Acceso
              //----------------------------------------------------------------------------------------
              this.$router.push('/');

            } else {
              if (datos.msg) { 
                this.p99AnuncioIA1('2','7','ERROR', this.f79DecodeHTML(datos.msg),-1);
              } else {
                this.p99AnuncioIA1('2','7','ERROR', '(E999801) Error Desconocido',-1);
              }

            }

        })
        .catch(error => {
          //-- Desactivar los Indicadores de Actividad
          this.$store.commit('p10StrRecibiendoDatos', false);

          //-- Mostrar el Mensaje de Error oportuno
          this.p99AnuncioIA1('2','7','ERROR', error.message,-1);
        });

    }



  },

  //=======================================================================
  //------------ Métodos a aplicar una vez CARGADA la Página  -------------
  //=======================================================================
  mounted() {
    //console.log('SVC00801 mounted')

    //-- Actualizar Módulo "Vivo"
    this.$store.commit('p10StrModuleName', 'SVC00801');

  }
}
</script>

<style type="scope">
</style>
